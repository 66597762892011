<template>
  <div class="container has-text-centered">
    <h1 class="title">Hello World</h1>
    <div>
      <input v-model="adventureId" placeholder="Adventure ID" class="input is-primary mb-2" />
      <input v-model="sessionId" placeholder="Session ID" class="input is-primary mb-2" />
      <input v-model.number="numberOfTeams" type="number" placeholder="No of teams" class="input is-primary mb-2" />
      <button @click="createTeams" class="button is-primary">Create Teams</button>
    </div>
    <div v-if="progress > 0" class="progress-bar">
      <div class="progress" :style="{ width: progress + '%' }"></div>
    </div>
    <p v-if="progressText" class="mt-2">{{ progressText }}</p>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'StressTester',
  data() {
    return {
      adventureId: 'KCMQ',
      sessionId: 'RDDH',
      faciKey: 'e49ab1c7-2eb3-42a8-9ba8-f5c6c2a491f4',
      numberOfTeams: 100,
      noOfChallengesPerTeam: 50,
      progress: 0,
      progressText: '',
      totalSteps: 0,
      currentStep: 0
    }
  },
  methods: {
    async createTeams() {
      this.progress = 0
      this.progressText = 'Creating teams...'
      this.totalSteps = this.numberOfTeams * (this.noOfChallengesPerTeam + 3) + this.numberOfTeams // +3 for create, join, start, and +numberOfTeams for submitting judgements
      this.currentStep = 0

      const teamPromises = []
      for (let i = 0; i < this.numberOfTeams; i++) {
        const teamName = uuidv4()
        teamPromises.push(this.processTeam(teamName))

        // Submit judgements every 5 teams
        if ((i + 1) % 5 === 0 || i === this.numberOfTeams - 1) {
          const judgementIds = await Promise.all(teamPromises)
          const flattenedJudgementIds = judgementIds.flat()
          await this.submitJudgements(flattenedJudgementIds)
          teamPromises.length = 0 // Clear the array
        }
      }

      this.progressText = 'Process completed!'
    },
    async processTeam(teamName) {
      const teamCode = await this.createTeam(teamName)
      if (!teamCode) return []

      await this.joinTeam(teamCode)
      this.currentStep++
      this.updateProgress()

      await this.startAdventure(teamCode)
      this.currentStep++
      this.updateProgress()

      await new Promise(resolve => setTimeout(resolve, 2000))

      const judgementIds = []
      for (let challengeIndex = 0; challengeIndex <= (this.noOfChallengesPerTeam - 1); challengeIndex++) {
        this.progressText = `Answering challenge ${challengeIndex + 1} for team ${teamCode}...`
        const judgementId = await this.answerChallenge(teamCode, challengeIndex)
        if (judgementId) {
          judgementIds.push(judgementId)
        }
        this.currentStep++
        this.updateProgress()
      }

      return judgementIds
    },
    async createTeam(teamName) {
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      return masterFunction({
        methodName: 'create-team',
        teamName: teamName,
        name: teamName,
        email: null,
        clan: null,
        adventureId: this.adventureId,
        sessionId: this.sessionId,
        stage: null,
        accessCode: null
      }).then(res => {
        if(res.data.teamCode){
          console.log('Team created successfully:', res.data.teamCode)
          return res.data.teamCode
        }
      }).catch(err => {
        console.error('Error creating team:', err.message)
        return null
      })
    },
    joinTeam(teamCode) {
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      return masterFunction({
        methodName: 'join-team',
        name: 'TESTING',
        teamCode: teamCode,
        sessionId: null
      }).then(res => {
        console.log('Joined team successfully:', teamCode)
      }).catch(err => {
        console.error('Error joining team:', err.message)
      })
    },
    startAdventure(teamCode) {
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      return masterFunction({
        methodName: 'start-adventure',
        teamCode: teamCode,
        removeTimeLimits: false,
        userLat: null,
        userLon: null,
        timezone: 8
      }).then(res => {
        console.log('Started adventure successfully:', teamCode)
      }).catch(err => {
        console.error('Error starting adventure:', err.message)
      })
    },
    answerChallenge(teamCode, challengeIndex) {
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      return masterFunction({
        methodName: 'answer-challenge',
        teamCode: teamCode,
        userName: 'TESTING',
        stage: 1,
        challengeIndex: challengeIndex,
        position: null,
        answerObj: {
          value: 'https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/judgementFiles%2Fclub-month%2F1726936088966.webp?alt=media&token=12a9d8a5-c8eb-4841-8110-f182a6a51d98',
          ext: '.webp'
        },
        completedWithTeamNames: null
      }).then(res => {
        console.log(`Answered challenge ${challengeIndex} successfully for team:`, teamCode)
        console.log('res.data', res.data)
        return res.data[0].judgementId
      }).catch(err => {
        console.error(`Error answering challenge ${challengeIndex} for team ${teamCode}:`, err.message)
        return null
      })
    },
    async submitJudgements(judgementIds) {
      this.progressText = 'Submitting judgements...'
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      for (const judgementId of judgementIds) {
        try {
          await masterFunctionAdmin({
            methodName: 'submit-judgement',
            faciKey: this.faciKey,
            judgementId: judgementId,
            comments: null,
            pointsAwarded: 5,
            isApproved: true
          })
          console.log('Judgement submitted successfully:', judgementId)
        } catch (err) {
          console.error('Error submitting judgement:', err.message)
        }
        this.currentStep++
        this.updateProgress()
        await new Promise(resolve => setTimeout(resolve, 200)) // Add a 1-second delay between judgement submissions
      }
    },
    updateProgress() {
      this.progress = (this.currentStep / this.totalSteps) * 100
    }
  },
  mounted() {
    // this.createTeams()
  }
}
</script>

<style scoped>
.container {
  margin-top: 20%;
}
.title {
  font-size: 3em;
  color: #333;
}
.input {
  margin-bottom: 10px;
}
.button {
  margin-top: 20px;
}
.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 20px;
}
.progress {
  height: 20px;
  background-color: #4caf50;
  width: 0;
  transition: width 0.4s ease;
}
</style>
